import Vue from 'vue';
import Router from 'vue-router';
import ARScene from '@/components/ARScene';
import Home from '@/components/Home';
import Location from '@/components/Location';
import RequestPermission from '@/components/Permission';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/scene',
      name: 'ARScene',
      component: ARScene
    },
    {
      path: '/location',
      name: 'Location',
      component: Location
    },
    {
      path: '/permission',
      name: 'Permission',
      component: RequestPermission
    }
  ]
});
