<template>
  <div class="popContainer">
    <img width="300" height="300" src="/images/imu-permission.png" />
    <button @click="onClick">点击授权传感器权限</button>
  </div>
</template>

<script>
export default {
  name: "ARScene",
  data() {
    return {
      perssionGranted: false
    }
  },
  methods: {
    /*判断客户端*/
    judgeClient() {
      let client = '';
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
        client = 'Android';
      } else {
        client = 'PC';
      }
      console.log('client = ', client);
      if (/(micromessenger)/i.test(navigator.userAgent)) {
        // 微信
      }
      return client;
    },
    onClick() {
      if (typeof DeviceMotionEvent.requestPermission === 'function') {
        // Handle iOS 13+ devices.
        const that = this;
        DeviceMotionEvent.requestPermission()
          .then((state) => {
            if (state === 'granted') {
              // window.addEventListener('devicemotion', handleOrientation);
              that.perssionGranted = true;
              // that.doStartup();
              this.$router.push({name:'location'})
            } else {
              console.error('Request to access the orientation was rejected');
            }
          })
          .catch(console.error);
      } else {
        // Handle regular non iOS 13+ devices.
        // window.addEventListener('devicemotion', handleOrientation);
      }
    },
  },
  mounted() {
  }
}
</script>

<style>

.popContainer{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.3);
  > img{
      width: 100%;
      height: 100%;
      object-fit: contain
  }
}

.float-position{
  font-size: 12px;
  position: fixed;
  z-index: 500!important;
  right: 0;
  top: 50%;
  width: 48px;
  height: 168px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

</style>