<template>
  <div id="ARScene">

  <p>{{ event }}</p>

  </div>
  
</template>

<script>
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js' //导入OBJLoader 读取obj模型
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js' //导入MTLLoader 读取mtl材质
import { ArToolkitProfile, ArToolkitSource, ArToolkitContext, ArMarkerControls} from 'ar-js-org/three.js/build/ar-threex.js';

export default {
  name: "ARScene",
  data () {
    return {
      camera: null,
      scene: null,
      renderer: null,
      light: null,
      controls: null,
      particleSystem: null,
      dropSpeed: 10,
      orientLon: 0,
      orientLat: 0,
      lat :0,
      lon :0,
      event:null,
    }
  },
  methods: {
    loadObj () {
      let that = this
      // 材质读取,包含材质
      new MTLLoader().load('/model/logo.mtl', materials => {
        materials.preload()
        // 模型读取
        new OBJLoader().setMaterials(materials).load('/model/logo.obj', obj => {
          obj.scale.set(20, 20, 20)
          obj.position.set(0, 0, 0)
          obj.rotation.set(35,30,30)
          //在场景中加入坐标系
          //let axes = new THREE.AxesHelper(100)
          that.scene.add(obj)
          that.createSprite()
          //that.scene.add(axes)
        }, function (xhr) {
          // onProgress回调
          console.log((xhr.loaded / xhr.total * 100) + '% loaded')
        }, function (err) {
          // onError回调
          console.log(err)
        })
      })
    },
    createPointRainy() {
        var texture = new THREE.TextureLoader().load("images/redpacket1.png");
        var geom = new THREE.BufferGeometry();
      
        var material = new THREE.PointsMaterial({
          size: 60,
          transparent: true, // 是否设置透明度
          opacity: 1, // 透明
          map: texture, // 粒子材质
          blending: THREE.NormalBlending,
          sizeAttenuation: true, // 是否相同尺寸
          color: 0xffffff
        });
        var positions = []
        var range = 30;
        for (var i = 0; i < 50; i++) {
          var particle = new THREE.Vector3(
            Math.random() * range - range / 2,
            Math.random() * range * 1.5,
            1 + (i / 10 - 80)
          )
          
          // 定义雨滴以多快的速度落下,纵向运动速度的范围是0.1～0.3
          particle.velocityY = (0.1 + Math.random() / 5) - 0.1;
          // 定义粒子（雨滴）如何水平移动,横向运动速度的范围是-0.16～+0.16
          particle.velocityX = ((Math.random() - 0.5) / 3) - 0.05;
          
          positions.push(particle);
        }

        geom.addAttribute('position', new THREE.BufferAttribute(positions, 3));
      
        var cloud = new THREE.ParticleSystem(geom, material);
        cloud.sortParticles = true;
        cloud.name = "name";
        this.scene.add(cloud);
      },
    createSprite(){
        // 创建粒子geometry 
        var geo = new THREE.BufferGeometry();
        // 创建粒子基本材质 
        var pMaterial = 
        new THREE.ParticleBasicMaterial({ 
          color: 0xFFFFFF, 
          size: 20, 
          map: THREE.ImageUtils.loadTexture( 
          "images/redpacket1.png" 
          ), 
          blending: THREE.AdditiveBlending, 
          transparent: true,
          depthWrite: false
        });
        // 位置数组
        const positions = [];
        // 随机颜色数组
        const colors = [];
        var r = 300
        for (let i = 0; i < 50; i++) {
          const x = Math.random() * r - r / 2;
          const y = Math.random() * r - r / 2;
          const z = Math.random() * r - r / 2;
          // positions
          positions.push(x, y, z);
        }

        geo.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

        //geo.addAttribute('position', new THREE.Float32BufferAttribute(pointsPosition, 3))
        geo.computeBoundingBox();

        this.particleSystem = new THREE.ParticleSystem(geo,pMaterial);
        this.particleSystem.sortParticles = true; 

        // 将粒子系统加入场景 
        this.scene.add(this.particleSystem); 
      },
      orient(event) {
        console.log("event" + event)
        this.event = JSON.stringify(event)
        this.orientLon = event.gamma;
        this.orientLat = event.beta - 70; // 减90°让默认状态是手机直立，但一般人手机都会向后仰一点，所以我少减了20°
    }
  },
  mounted() {
    let that = this
    ArToolkitContext.baseURL = './'
    console.log("ARScene mounted");

    // init renderer
    var renderer = new THREE.WebGLRenderer({
      antialias : true,
      alpha: true
    });

    // renderer.setClearColor(new THREE.Color('lightgrey'), 0)
    // renderer.setPixelRatio( 2 );
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.domElement.style.position = 'absolute'
    renderer.domElement.style.top = '0px'
    renderer.domElement.style.left = '0px'
    document.body.appendChild( renderer.domElement ); // We should be able to specify an html element to append AR.js related elements to.

    // array of functions for the rendering loop
    var onRenderFcts= [];

    // init scene and camera
    that.scene = new THREE.Scene();
    console.log(that.scene)
    //////////////////////////////////////////////////////////////////////////////////
    //  Initialize a basic camera
    //////////////////////////////////////////////////////////////////////////////////

    // Create a camera
    var camera = new THREE.Camera();
    that.scene.add(new THREE.AmbientLight(0xDFDFDF))// 环境光
      var light = new THREE.DirectionalLight(0xdfebff, 0.45)// 从正上方（不是位置）照射过来的平行光，0.45的强度
      light.position.set(50, 200, 100)
      light.position.multiplyScalar(0.3)
      that.scene.add(light)

    camera.position.set(10, 90, 65)
    camera.lookAt(that.scene.position)
    that.scene.add(camera);
    const artoolkitProfile = new ArToolkitProfile();
    artoolkitProfile.sourceWebcam(); // Is there good reason for having a function to set the sourceWebcam but not the displayWidth/Height etc?
    
     // add existing parameters, this is not well documented
    let additionalParameters = {
        // Device id of the camera to use (optional)
        deviceId: null,
        // resolution of at which we initialize in the source image
        sourceWidth: 640,
        sourceHeight: 480,
        // resolution displayed for the source
        displayWidth: 640,
        displayHeight: 480,
    }
    
    Object.assign(artoolkitProfile.sourceParameters, additionalParameters);
    console.log(artoolkitProfile.sourceParameters); // now includes the additionalParameters

    const arToolkitSource = new ArToolkitSource(artoolkitProfile.sourceParameters);

    arToolkitSource.init(function onReady(){
      onResize();
    })

    // handle resize
    window.addEventListener('resize', function(){
      onResize(); 
    })

    // resize is not called for the canvas on init. The canvas with the cube seems to be resized correctly at start.
    // Is that maybe a vue-specific problem?
    function onResize(){
      arToolkitSource.onResizeElement()
      arToolkitSource.copyElementSizeTo(renderer.domElement)
      if(arToolkitContext.arController !== null){
        arToolkitSource.copyElementSizeTo(arToolkitContext.arController.canvas)
      }
    }
      

    ////////////////////////////////////////////////////////////////////////////////
    //          initialize arToolkitContext
    ////////////////////////////////////////////////////////////////////////////////

    // create atToolkitContext
    var arToolkitContext = new ArToolkitContext({
      debug: false,
      cameraParametersUrl: ArToolkitContext.baseURL + 'data/camera_para.dat',
      detectionMode: 'mono',
      canvasWidth: 640,
      canvasHeight: 490,
      imageSmoothingEnabled : true, // There is still a warning about mozImageSmoothingEnabled when using Firefox
    })
    
    // initialize it
    arToolkitContext.init(function onCompleted(){
      // copy projection matrix to camera
      camera.projectionMatrix.copy( arToolkitContext.getProjectionMatrix() );
    })

    // update artoolkit on every frame
    onRenderFcts.push(function(){
      if( arToolkitSource.ready === false ) return

      arToolkitContext.update( arToolkitSource.domElement )
    })

    ////////////////////////////////////////////////////////////////////////////////
    //          Create a ArMarkerControls
    ////////////////////////////////////////////////////////////////////////////////

    var markerGroup = new THREE.Group()
    that.scene.add(markerGroup)

    var markerControls = new ArMarkerControls(arToolkitContext, markerGroup, {
      type: 'pattern',
      patternUrl: ArToolkitContext.baseURL + 'data/patt.hiro',
      smooth: true,
      smoothCount: 5,
      smoothTolerance: 0.01,
      smoothThreshold: 2
    })

    //////////////////////////////////////////////////////////////////////////////////
    //  render the whole thing on the page
    //////////////////////////////////////////////////////////////////////////////////
    this.loadObj();
    onRenderFcts.push(function(){
      if (that.particleSystem) that.particleSystem.rotation.y += 0.01;
      renderer.render(that.scene, camera);
    })
    
      // run the rendering loop
    var lastTimeMsec = null;
    requestAnimationFrame(function animate(nowMsec){
      // keep looping
      requestAnimationFrame(animate);
      // measure time
      lastTimeMsec = lastTimeMsec || nowMsec-1000/60
      var deltaMsec = Math.min(200, nowMsec - lastTimeMsec)
      lastTimeMsec = nowMsec
      // call each update function
      onRenderFcts.forEach(function(onRenderFct){
        onRenderFct(deltaMsec/1000, nowMsec/1000)
      })
    }) 
    window.addEventListener('devicemotion', function () {
          var acceleration = event.accelerationIncludingGravity;
          var x = acceleration.x;
          var y = acceleration.y;
          var z = acceleration.z;
          that.event = JSON.stringify(this.event)
          // do your want to do
      }, false);
    
  }
}
</script>

<style>
background {
  width: 100%;
  height: 100%;
  background: #ff00ff;
}
</style>