<template>
  <div>
    <button v-if="!perssionGranted" class="float-position" @click="onClick">传感器授权</button>
    <div id="divCanvas">
      <canvas id="canvas1" width="100%" height="100%" @click="onClick">浏览器不支持canvas<!-- 如果不支持会显示这段文字 --></canvas>
      <p>{{ event }}</p>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js' //导入OBJLoader 读取obj模型
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js' //导入MTLLoader 读取mtl材质
import * as THREEx from '/node_modules/@ar-js-org/ar.js/three.js/build/ar-threex-location-only.js';


export default {
  name: "ARScene",
  metaInfo: {
      title: 'This is the test',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no,' }
      ]
    },
  data () {
    return {
      camera: null,
      scene: null,
      renderer: null,
      light: null,
      controls: null,
      particleSystem: null,
      dropSpeed: 10,
      orientLon: 0,
      orientLat: 0,
      lat :0,
      lon :0,
      event:null,
      perssionGranted: false,
      floatingObj: null,
      particleSystem: null
    }
  },
  methods: {
    /*判断客户端*/
    judgeClient() {
      let client = '';
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
        client = 'Android';
      } else {
        client = 'PC';
      }
      console.log('client = ', client);
      if (/(micromessenger)/i.test(navigator.userAgent)) {
        // 微信
      }
      return client;
    },
    onClick() {
      if (typeof DeviceMotionEvent.requestPermission === 'function') {
        // Handle iOS 13+ devices.
        const that = this;
        DeviceMotionEvent.requestPermission()
          .then((state) => {
            if (state === 'granted') {
              // window.addEventListener('devicemotion', handleOrientation);
              that.perssionGranted = true;
              // that.doStartup();
            } else {
              console.error('Request to access the orientation was rejected');
            }
          })
          .catch(console.error);
      } else {
        // Handle regular non iOS 13+ devices.
        // window.addEventListener('devicemotion', handleOrientation);
      }
    },
    loadObj (arjs) {
      let that = this
      // 材质读取,包含材质
      new MTLLoader().load('/model/logo.mtl', materials => {
        materials.preload()
        // 模型读取
        new OBJLoader().setMaterials(materials).load('/model/logo.obj', obj => {
          obj.position.set(0, 0, 0)
          obj.rotation.set(0,Math.PI/2.0,0)
          obj.scale.set(20, 20, 20)
          //在场景中加入坐标系
          //let axes = new THREE.AxesHelper(100)
          // that.scene.add(obj)
          // that.createSprite()
          //that.scene.add(obj)
          //that.scene.add(axes)
          that.floatingObj = obj;
          that.floatingObj.name = "floatingObj"
          arjs.add(that.floatingObj, 0, 0 + 0.0005, Math.PI / 6.0); 
        }, function (xhr) {
          // onProgress回调
          console.log((xhr.loaded / xhr.total * 100) + '% loaded')
        }, function (err) {
          // onError回调
          console.log(err)
        })
      })
    },
    createSprite() {
      console.log('creating particle system');
      // 创建粒子geometry 
      var geo = new THREE.BufferGeometry();
      // 创建粒子基本材质 
      var pMaterial = 
      new THREE.ParticleBasicMaterial({ 
        color: 0xFFFFFF, 
        size: 30, 
        map: THREE.ImageUtils.loadTexture( 
        "images/redpacket1.png" 
        ), 
        // blending: THREE.AdditiveBlending, 
        transparent: true,
        depthWrite: false
      });
      // 位置数组
      const positions = [];
      // 随机颜色数组
      const colors = [];
      var r = 300
      for (let i = 0; i < 100; i++) {
        const x = Math.random() * r - r / 2;
        const y = Math.random() * r - r / 2;
        const z = Math.random() * r - r / 2;
        // positions
        positions.push(x, y, z);
      }

      geo.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
      geo.computeBoundingBox();

      this.particleSystem = new THREE.ParticleSystem(geo,pMaterial);
      this.particleSystem.sortParticles = true; 
	  this.particleSystem.name = "particleSystem"

      // 将粒子系统加入场景 
      // scene.add(particleSystem); 
      console.log('creating particle system done');
    },
    doStartup() {
      const canvas = document.getElementById('canvas1');

      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(60, 384.0/640.0, 0.1, 1000);
      var renderer = new THREE.WebGLRenderer({
        antialias : true,
        alpha: true
      });
      //renderer.setSize( 384, 640 );
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.domElement.style.position = 'absolute'
      renderer.domElement.style.top = '0px'
      renderer.domElement.style.left = '0px'
      document.body.appendChild( renderer.domElement ); 
      

      const arjs = new THREEx.LocationBased(scene, camera);
      const cam = new THREEx.WebcamRenderer(renderer);

      // const geom = new THREE.BoxGeometry(20, 20, 20);
      // const mtl = new THREE.MeshBasicMaterial({color: 0xff0000});
      // // const box = new THREE.Mesh(geom, mtl);
      this.loadObj(arjs, scene);

      scene.add(new THREE.AmbientLight(0xDFDFDF))// 环境光
      var light = new THREE.DirectionalLight(0xdfebff, 0.45)// 从正上方（不是位置）照射过来的平行光，0.45的强度
      light.position.set(50, 200, 100)
      light.position.multiplyScalar(0.3)
      scene.add(light)
      this.createSprite();

      // Create the device orientation tracker
      const deviceOrientationControls = new THREEx.DeviceOrientationControls(camera);

      // Change this to a location close to you (e.g. 0.001 degrees of latitude north of you)

      let that = this;
      arjs.startGps();
      // arjs.fakeGps(-0.72, 51.05);
      // while(that.floatingObj === null);
      // arjs.add(that.floatingObj, -0.72, 51.05 + 0.001); 
      arjs.add(that.particleSystem, 0, 0); 
      
      let first = true;
      arjs.on("gpsupdate", pos => {
          console.log('gpsupdate: ', pos.coords.longitude, ', ', pos.coords.latitude);
          if(first && that.floatingObj !== null) {
              // setupObjects(pos.coords.longitude, pos.coords.latitude);
              console.log("update objects longitude: " + pos.coords.longitude + ";latitude : " + pos.coords.latitude);
              arjs.setWorldPosition(that.floatingObj, pos.coords.longitude, pos.coords.latitude + 0.0005, Math.PI / 6.0);
              arjs.setWorldPosition(that.particleSystem, pos.coords.longitude, pos.coords.latitude,);
              first = false;
          }
      });

      arjs.on("gpserror", code => {
          alert(`GPS error: code ${code}`);
      });

      requestAnimationFrame(render);

      function render() {
//		  console.log("canvas.width :" + canvas.width + "; canvas.clientWidth :" + canvas.clientWidth
//		  + "; canvas.height :" + canvas.height+ "; canvas.clientHeight :" + canvas.clientHeight)
          if(canvas.width != canvas.clientWidth || canvas.height != canvas.clientHeight) {
			  if(canvas.clientWidth != 0 && canvas.clientHeight != 0) {
				  renderer.setSize(canvas.clientWidth, canvas.clientHeight, false);
				  const aspect = canvas.clientWidth/canvas.clientHeight;
				  camera.aspect = aspect;
				  console.log("camera.aspect :" + aspect + "; canvas.clientWidth :" + canvas.clientWidth+ "; canvas.clientHeight :" + canvas.clientWidth)
				  camera.updateProjectionMatrix();
			  }
          }

          // Update the scene using the latest sensor readings
          deviceOrientationControls.update();

          cam.update();
          renderer.render(scene, camera);
          requestAnimationFrame(render);
      }
      //  模型点击事件
      // 创建一个raycaster实例用于检测点击
      var raycaster = new THREE.Raycaster();
      var mouse = new THREE.Vector2();

	  
	  // 获取场景中的相机和渲染器
//	  const camera = arToolkitContext.arController.getCamera();
//	  const renderer = arToolkitContext.renderer;
	   // 创建一些物体
	   let geometry = new THREE.BoxGeometry(1, 1, 1);
	   let material = new THREE.MeshNormalMaterial();
	   let cube = new THREE.Mesh(geometry, material);
	   let container = document.getElementById("divCanvas")
	   scene.add(cube);
	  function onTouchStart(event) {
	      // 阻止浏览器默认行为
	      event.preventDefault();
	   
	      // 获取触摸点的坐标
	      const touch = event.touches[0]; // 假设只处理单点触摸
		  
		  // 通过鼠标点击位置,计算出 raycaster 所需点的位置,以屏幕为中心点,范围 -1 到 1
		  //这里的container就是画布所在的div，也就是说，这个是要拿整个scene所在的容器来界定的
		  let getBoundingClientRect = container.getBoundingClientRect()
		  mouse.x = ((touch.clientX - getBoundingClientRect.left) / container.offsetWidth) * 2 - 1;
		  mouse.y = -((touch.clientY - getBoundingClientRect.top) / container.offsetHeight) * 2 + 1;
		  console.log("mouse.x : " + mouse.x + "mouse.y : " + mouse.y);
	   
	      // 更新射线位置
	      raycaster.setFromCamera(mouse, camera);
	   
	      // 计算物体和射线的交点
	      const intersects = raycaster.intersectObjects(scene.children, true);
		  console.log(intersects);
	      // 如果有物体被击中
	      if (intersects.length > 0) {
	          // 获取被击中的物体
	          const intersection = intersects[0];
	   
	          // 执行你想要的操作，比如设置世界位置
	          //arjs.setWorldPosition(intersection.object, new THREE.Vector3(0, 0, 0));
			      // arjs.setWorldPosition(intersects[0].point, cube);
            window.location = 'https://www.baidu.com';
	      }
	  }
	   
	  // 添加触摸事件监听
	  renderer.domElement.addEventListener('touchstart', onTouchStart);

//      window.addEventListener( 'click', onMouseClick, false );
    
      //
      // const rotationStep = THREE.Math.degToRad(2);

      // let mousedown = false, lastX =0;

      // window.addEventListener("mousedown", e=> {
      //     mousedown = true;
      // });

      // window.addEventListener("mouseup", e=> {
      //     mousedown = false;
      // });

      // window.addEventListener("mousemove", e=> {
      //     if(!mousedown) return;
      //     if(e.clientX < lastX) {
      //         camera.rotation.y -= rotationStep;
      //         if(camera.rotation.y < 0) {
      //             camera.rotation.y += 2 * Math.PI;
      //         }
      //     } else if (e.clientX > lastX) {
      //         camera.rotation.y += rotationStep;
      //         if(camera.rotation.y > 2 * Math.PI) {
      //             camera.rotation.y -= 2 * Math.PI;
      //         }
      //     }
      //     lastX = e.clientX;
      // });
    }
  },
  mounted() {
    console.log('mounted begin');
    let clientType = this.judgeClient();
    console.log('device type = ', clientType);
    if (clientType == 'iOS') {
      this.perssionGranted = false;
    } else {
      this.perssionGranted = true;
    }
    this.doStartup();
    console.log('mounted end');
  },
}
</script>

<style>
background {
  width: 100%;
  height: 100%;
  background: #000000;
}

.float-position{
  font-size: 12px;
  position: fixed;
  z-index: 500!important;
  right: 0;
  top: 50%;
  width: 48px;
  height: 168px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
</style>
